<template>
  <div class="directory-list-container">
    <b-input
      ref="searchInput"
      v-model="searchKeyword"
      placeholder="Search..."
      type="search"
      icon="magnify"
      class="search-variable-input"
    />
    <hr>
    <VariableTree
      v-bind="variableTreeProps"
      label="Choose a value"
      :items="filteredItems"
      :show-parent-node-options="true"
      :show-leaf-node-options="false"
      :item-dropdown="VariableHeaderDropdown"
      @node-selected="handleItemSelect"
    />
    <VariableMappingModal 
      v-model="isVariableMappingModalActive"
      :selected-option="selectedVariable"
      @apply-mapping="applyMapping"
    />
  </div>
</template>

<script >
import { computed, defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import VariableHeaderDropdown from './VariableHeaderDropdown.vue';
import VariableMappingModal from '@/modules/core/components/variable-mapper/VariableMappingModal.vue';
import { ON_MAPPING_VARIABLES, variablesBus } from './variablesTreePopupBus';
import { delay } from '@/helpers/util';

// -- child components -- //
const VariableTree = defineAsyncComponent(() => import('@/modules/core/components/variable-tree/VariableTree.vue'));

// -- component props -- //
const __sfc_main = {};
__sfc_main.props = {
  items: {
    type: Array,
    required: true // expected array of object (check VariableTree.vue for shape of each object)
  },

  command: {
    // refer: https://tiptap.dev/api/utilities/suggestion#command and https://github.com/ueberdosis/tiptap/blob/main/packages/suggestion/src/suggestion.ts
    type: [Function, Object],
    default: null
  },
  variableTreeProps: {
    type: Object,
    default: () => ({})
  },
  editor: {
    // tip-tap editor instance
    type: Object,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  // -- component events --//
  const emit = __ctx.emit;

  // -- search variable logic -- //
  const searchKeyword = ref('');
  const filteredItems = computed(() => {
    if (searchKeyword.value) {
      return props.items.filter(item => item.name.toLowerCase().includes(searchKeyword.value.toLowerCase()));
    }
    return props.items;
  });

  // -- search input auto focus logic -- //
  const searchInput = ref(null);
  const setSearchInputAutoFocus = () => {
    searchInput.value.focus();
  };
  onMounted(() => {
    setSearchInputAutoFocus();
  });

  // -- select dropdown item logic -- //
  const handleItemSelect = item => {
    if (props.command) {
      // command prop is something used by editor and is only provided by it (check Editor.vue)
      props.command({
        reference: item.reference,
        name: item.name
      });
    } else {
      // if this component is used outside of editor, do not pass command prop as this allows to handle more generic use cases
      emit('node-selected', item);
    }
  };

  //-- variable auto mapping logic --//
  const isVariableMappingModalActive = ref(false);
  const selectedVariable = ref({});
  const handleMappingVariables = selectedOption => {
    isVariableMappingModalActive.value = true;
    selectedVariable.value = selectedOption;
  };
  variablesBus.subscribe(ON_MAPPING_VARIABLES, handleMappingVariables);
  const applyMapping = async payload => {
    const {
      variables,
      prefix,
      suffix
    } = payload;
    const isPrefixPresent = Object.keys(prefix).length;
    const isSuffixPresent = Object.keys(suffix).length;
    await delay(200); // wait for Mapping modal to hide
    props.editor.commands.undo(); // remove extra @ symbol
    variables.forEach((variable, index) => {
      const prefixText = isPrefixPresent ? getAffix(prefix, index) : '';
      const suffixText = isSuffixPresent ? getAffix(suffix, index) : '';
      props.editor.commands.insertContent(prefixText);
      props.editor.commands.insertContent({
        type: 'mention',
        attrs: {
          reference: variable.reference,
          name: variable.name
        }
      });
      if (index < variables.length - 1) {
        props.editor.commands.insertContent(suffixText);
      }
    });
  };
  const getAffix = (affix, index) => {
    let affixText = '';
    affixText += (affix.isDistinct ? affix.customContent[index] : affix.customContent) || '';
    affixText += affix.delimiter === 'custom' ? affix.customDelimiter : affix.delimiter;
    return affixText;
  };
  return {
    VariableHeaderDropdown,
    searchKeyword,
    filteredItems,
    searchInput,
    handleItemSelect,
    isVariableMappingModalActive,
    selectedVariable,
    applyMapping
  };
};
__sfc_main.components = Object.assign({
  VariableTree,
  VariableMappingModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.directory-list-container {
    padding: 10px;
    box-shadow: 0 0 10px rgba(black, 0.18);
    background: white;
    width: 290px;
    .search-variable-input {
      input {
        font-size: 0.85rem;
      }
      .icon {
        height: 100%;
      }
    }
    hr {
      margin: 12px 0;
    }
    .menu {
      max-height: 225px;
      overflow-y: auto;
      font-size: 0.85rem;
      .menu-list {
        li {
          ul {
            margin: 0.25rem 0.75em;
          }
          a {
            padding: 0 0.75em;
            .icon {
              &:first-child {
                i {
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }
}
</style>
