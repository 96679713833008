/**
 * Generates JSON blocks (required by json builder) from a valid json
 * @param {*} jsonInput
 */
export const generateJsonBlocks = (jsonInput) => {
    if (Array.isArray(jsonInput)) {
        throw 'Top level array not supported yet!';
    } else if (jsonInput === null) {
        throw 'Input JSON is null!';
    }
    const blocks = createObjectBlock(jsonInput, 'json').children;
    return blocks;
};

/**
 * @param {string} [name]
 */
const generateBlock = (name) => {
    const block = {
        children: [],
        type: '',
        value: ''
    };
    if (name !== undefined) {
        block.name = name;
    }
    return block;
};

const isPrimitive = (inputValue) => {
    return ['string', 'number', 'boolean', 'null', 'undefined'].includes(typeof inputValue);
};

/**
 * @param {number | string | boolean | null | undefined} inputValue
 * @param {string} [name]
 */
const createPrimitiveBlock = (inputValue, name = undefined) => {
    const block = generateBlock(name);
    block.type = typeof inputValue === 'number' ? 'Number' : 'String';
    block.value = `<p>${inputValue}</p>`;
    return block;
};

/**
 * @param {object} inputValue
 * @param {string} [name]
 */
const createArrayBlock = (inputValue, name) => {
    const block = generateBlock(name);
    block.type = 'Array';
    block.value = inputValue.map((item) => {
        if (isPrimitive(item)) {
            return createPrimitiveBlock(item);
        } else if (Array.isArray(item)) {
            throw 'Nested arrays not supported yet!';
        } else {
            return createObjectBlock(item);
        }
    });
    return block;
};

/**
 * @param {object} inputValue
 * @param {string} name
 */
const createObjectBlock = (inputValue, name) => {
    const block = generateBlock(name);
    block.type = 'Object';
    block.name = name;
    block.children = Object.entries(inputValue).map(([ key, value ]) => {
        if (isPrimitive(value)) {
            return createPrimitiveBlock(value, key);
        } else if (Array.isArray(value)) {
            return createArrayBlock(value, key);
        } else {
            return createObjectBlock(value, key);
        }
    });
    return block;
};
