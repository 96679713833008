<template>
  <div>
    <small>Array to filter</small>
    <Editor
      v-model="formulaInfo.data.array"
      :input="true"
      :formula="true"
    />
    <b-field
      class="my-2"
      label="Criteria"
    >
      <b-radio-button
        v-model="formulaInfo.data.query.condition"
        native-value="All"
        size="is-small"
      >
        <span>All</span>
      </b-radio-button>
      <b-radio-button
        v-model="formulaInfo.data.query.condition"
        native-value="Any"
        size="is-small"
      >
        <span>Any</span>
      </b-radio-button>
    </b-field>
    <div class="columns">
      <div class="column is-4">
        <small>Property name</small>
      </div>
      <div class="column is-3">
        <small>Operator</small>
      </div>
      <div class="column is-4">
        <small>Property value</small>
      </div>
      <div class="column is-1">
        <small />
      </div>
    </div>
    <div
      v-for="(prop, index) in formulaInfo.data.query.properties"
      :key="'query-prop' + index"
      class="columns"
    >
      <div class="column is-4">
        <Editor
          v-model="prop.name"
          :input="true"
          :formula="true"
        />
      </div>
      <div class="column is-3">
        <b-select
          v-model="prop.operator"
          size="is-small"
          expanded
        >
          <option
            v-for="(operator, oIndx) in operators"
            :key="'operator' + oIndx"
          >
            {{ operator }}
          </option>
        </b-select>
      </div>
      <div class="column is-4">
        <Editor
          v-model="prop.value"
          :input="true"
          :formula="true"
        />
      </div>
      <div class="column is-1">
        <b-button
          type="is-danger"
          size="is-small"
          @click="deleteProp(index)"
        >
          X
        </b-button>
      </div>
    </div>
    <b-button
      class="is-primary"
      size="is-small"
      expanded
      @click="addProp()"
    >
      Add property criteria
    </b-button>
    <label class="label mt-3">Extract properties from the object</label>
    <div
      v-for="(property, propertyIndex) in formulaInfo.data.expandedProperties"
      :key="'condition-prop' + propertyIndex"
    >
      <small>Response variable name</small>
      <b-input
        v-model="property.name"
        size="is-small"
        expanded
      />
    </div>
    <b-button
      expanded
      size="is-small"
      class="my-3"
      @click="addExpandedProperty()"
    >
      Add property variable
    </b-button>

    <b-button
      expanded
      size="is-small"
      type="is-primary"
      @click="saveVariables()"
    >
      Save variables
    </b-button>
  </div>
</template>

<script >
// libs
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  //-- use composables --//
  const route = useRoute();
  const moduleStore = useModuleStore();
  const operators = ref(['equals', 'not equal']);

  //-- formula logic --//
  const formulaInfo = ref({
    formula: 'FILTER ARRAY',
    data: {
      array: '',
      query: {
        condition: 'All',
        properties: [{
          name: '',
          operator: 'equals',
          value: ''
        }]
      },
      expandedProperties: [{
        name: ''
      }]
    }
  });
  let avoidSavingFormula = false;
  const addProp = () => {
    formulaInfo.value.data.query.properties.push({
      name: '',
      operator: 'equals',
      value: ''
    });
  };
  const addExpandedProperty = () => {
    formulaInfo.value.data.expandedProperties.push({
      name: ''
    });
  };
  const deleteProp = index => {
    formulaInfo.value.data.query.properties.splice(index, 1);
  };
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });

  //-- variables logic --//
  const saveVariables = async () => {
    let variables = [];
    formulaInfo.value.data.expandedProperties.forEach(variable => {
      variables.push({
        name: variable.name,
        reference: variable.name
      });
    });
    if (variables.length) {
      await createVariables(variables);
    }
  };
  const createVariables = async variables => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await moduleStore.createVariable(appId, moduleId, {
        module_id: moduleId,
        node_id: null,
        node_name: 'Expanded array',
        variables: variables
      });
    } catch (err) {
      console.error(err);
    }
  };
  return {
    operators,
    formulaInfo,
    addProp,
    addExpandedProperty,
    deleteProp,
    saveVariables
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
