<template>
  <div>
    <b-field
      v-if="showUploadButton"
      class="file is-primary"
    >
      <b-upload
        size="is-small"
        accept=".json"
        @input.native="uploadJson"
      >
        <span class="file-cta">
          <b-icon
            class="file-icon"
            icon="upload"
          />
          <span class="file-label">Upload JSON</span>
        </span>
      </b-upload>
    </b-field>
    <section>
      <b-collapse
        v-for="(property, index) in builderNodes"
        :key="index"
        :open="false"
        class="mb-2 variable-builder"
        animation="slide"
      >
        <template #trigger="slotProps">
          <div
            class="mb-2 trigger-container"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <div
              class="px-2 py-2 is-clipped"
            >
              <span
                v-if="property.name !== ''"
                style="font-weight: 700;"
              >
                {{ property.name }}
              </span>
              <span
                v-else
                style="color: rgb(136, 136, 136);"
              >
                -- enter a variable name --
              </span>
            </div>
  
            <div class="px-2 py-2 delete-div">
              <b-button @click="deleteProperty(index)">
                <b-icon
                  icon="delete-outline"
                  type="is-danger"
                />
              </b-button>
            </div>
  
            <div class="px-2 py-2">
              <b-icon :icon="slotProps.open ? 'menu-down' : 'menu-up'" />
            </div>
          </div>
        </template>
  
        <template #default>
          <div class="columns mb-0">
            <div class="column">
              <BaseInput
                v-model="property.name"
                label="Property name"
                variant="list"
              />
            </div>
            <div class="column">
              <BaseSelect
                v-model="property.type"
                label="Property type"
                variant="list"
              >
                <option
                  v-for="(type, typeIndex) in types"
                  :key="typeIndex"
                >
                  {{ type }}
                </option>
              </BaseSelect>
            </div>
          </div>
  
          <div
            v-if="['String', 'Number', 'Boolean'].includes(property.type)"
          >
            <Editor
              v-model="property.value"
              :formula="true"
              :input="true"
              label="Property value"
            />
          </div>
  
          <div
            v-if="property.type === 'Object'"
            class="nested"
          >
            <JsonBuilder
              v-model="property.children"
              :show-upload-button="false"
            />
          </div>
  
          <div
            v-if="property.type === 'Array'"
            class="nested"
          >
            <b-switch
              v-model="property.dynamic"
            >
              Use dynamic value / variable
            </b-switch>
            <div v-if="property.dynamic">
              <Editor
                v-model="property.value"
                :formula="true"
                :input="true"
                label="Dynamic array"
              />
            </div>
            <div v-else>
              <div
                v-for="(arrayProperty, arrayIndex) in property.value"
                :key="arrayIndex"
              >
                <div class="columns my-2">
                  <div class="column is-10">
                    <BaseSelect
                      v-model="arrayProperty.type"
                      label="Property type"
                      variant="list"
                    >
                      <option
                        v-for="(type, i) in typesForArray"
                        :key="i"
                      >
                        {{ type }}
                      </option>
                    </BaseSelect>
                  </div>
                  <div class="column is-2 mt-5 delete-div">
                    <b-button @click="deleteItem(index, arrayIndex)">
                      <b-icon
                        icon="delete-outline"
                        type="is-danger"
                      />
                    </b-button>
                  </div>
                </div>
  
                <div
                  v-if="['String', 'Number', 'Boolean'].includes(arrayProperty.type)"
                >
                  <Editor
                    v-model="arrayProperty.value"
                    :formula="true"
                    :input="true"
                    label="Property value"
                  />
                </div>
  
                <div
                  v-if="arrayProperty.type === 'Object'"
                  class="nested"
                >
                  <JsonBuilder
                    v-model="arrayProperty.children"
                    :show-upload-button="false"
                  />
                </div>
              </div>
              <b-button
                size="is-small"
                class="my-2"
                expanded
                @click="addItem(index)"
              >
                Add item to the array
              </b-button>
            </div>
          </div>
        </template>
      </b-collapse>
    </section>
  
    <b-button
      size="is-small"
      expanded
      type="is-primary"
      @click="addProperty()"
    >
      Add property
    </b-button>
  </div>
</template>
  
<script >
// libs
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { useBuefy } from '@/hooks/buefy';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import { generateJsonBlocks } from './jsonBuilder';
const JsonBuilder = defineAsyncComponent(() => import('./JsonBuilder.vue'));
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor.vue'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Array,
    required: true
  },
  showUploadButton: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const buefy = useBuefy();
  const builderNodes = ref({});
  let avoidEmittingNodes = false;
  const types = ref(['--select a type--', 'String', 'Number', 'Boolean', 'Object', 'Array']);
  const typesForArray = ref(['--select a type--', 'String', 'Number', 'Boolean', 'Object']);
  onMounted(() => {
    avoidEmittingNodes = true;
    builderNodes.value = cloneDeep(props.value);
  });
  debouncedWatch(() => props.value, () => {
    avoidEmittingNodes = true;
    builderNodes.value = cloneDeep(props.value);
  }, {
    deep: true,
    debounce: 300
  });
  debouncedWatch(() => builderNodes.value, () => {
    if (avoidEmittingNodes) {
      avoidEmittingNodes = false;
    } else {
      emit('input', builderNodes.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  const addProperty = () => {
    builderNodes.value.push({
      name: '',
      type: '--select a type--',
      value: '',
      children: []
    });
  };
  const addItem = index => {
    if (!Array.isArray(builderNodes.value[index].value)) {
      builderNodes.value[index].value = [];
    }
    builderNodes.value[index].value.push({
      type: '--select a type--',
      value: '',
      dynamic: false,
      children: []
    });
  };
  const deleteProperty = index => {
    builderNodes.value.splice(index, 1);
  };
  const deleteItem = (index, arrayIndex) => {
    builderNodes.value[index].value.splice(arrayIndex, 1);
  };
  const transformJson = event => {
    try {
      const jsonInput = JSON.parse(event.target.result);
      const blocks = generateJsonBlocks(jsonInput);
      if (builderNodes.value && builderNodes.value.length) {
        buefy.dialog.confirm({
          title: 'Confirm Overriding JSON',
          message: '<div style="padding: 1em;">Are you sure you want to <b>override</b> existing JSON?</div>',
          confirmText: 'Yes',
          cancelText: 'No',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            builderNodes.value = blocks;
          }
        });
      } else {
        builderNodes.value = blocks;
      }
    } catch (err) {
      console.error(err);
    }
  };
  const uploadJson = event => {
    const reader = new FileReader();
    reader.onload = transformJson;
    reader.readAsText(event.target.files[0]);
    event.target.value = '';
  };
  return {
    builderNodes,
    types,
    typesForArray,
    addProperty,
    addItem,
    deleteProperty,
    deleteItem,
    uploadJson
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor,
  JsonBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
  
<style type="scss">
.property {
  padding: 10px 0;
  border-bottom: 1px solid #4943FC;
}

.nested {
  border: 1px solid #15C4BA;
  padding: 40px 20px 20px;
  margin-bottom: 20px;
}
</style>
